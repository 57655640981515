import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


const Error = React.lazy(() => import("./components/pages/Error"));
 
// Listings
const Listingdetailstwo = React.lazy(() => import("./components/pages/Listingdetailstwo"));


function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Listingdetailstwo} />
          <Route path="/error-404" component={Error} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
